<script setup lang="ts">
import { useAuthStore } from '~/store/auth';
import { useModalStore } from '~/store/modal';

const props = defineProps({
    isOpen: {
        default: false,
        type: Boolean,
    },
});
const emit = defineEmits(["close", "update:isOpen"]);
const isOpenSubMenu = ref(false);
const isShowProfile = ref(false);
const isLogin = ref(false);
const dropdown: any = ref(null); // The dropdown DOM element is stored in dropdown.value after rendering

function showProfile() {
    isShowProfile.value = !isShowProfile.value;
}
function showLogin() {
    isLogin.value = !isLogin.value;
}
function openSubMenu() {
    isOpenSubMenu.value = !isOpenSubMenu.value;
}
const handleClickOutside = (event: any) => {
    if (dropdown.value && !dropdown.value.contains(event.target)) {
        closeDropdown();
    }
};
const closeDropdown = () => {
    isShowProfile.value = false;
    isLogin.value = false;
};
const isRegisterModalOpen = ref(false);
const isOtpModalOpen = ref(false);
const loggedInCustomerId = ref('');
const loggedInCustomerMobileNumber = ref(0);
const modalStore = useModalStore();
const adminUrl = import.meta.env.VITE_APP_ADMIN_URL
const store = useAuthStore();
const myboatrideContact = import.meta.env.VITE_APP_MYBOATRIDE_CONTACT;
const myboatrideEmail = import.meta.env.VITE_APP_MYBOATRIDE_EMAIL;
const myboatrideWhatsapp = import.meta.env.VITE_APP_MYBOATRIDE_WHATSAPP_NUMBER;

const openLoginModal = () => {
    closeRegisterModal();
    closeOtpModal();
    modalStore.openLoginModal();
};

const openRegisterModal = () => {
    modalStore.closeLoginModal();
    isRegisterModalOpen.value = true;
};

const closeRegisterModal = () => {
    modalStore.closeLoginModal();
    isRegisterModalOpen.value = false;
};

const openOtpModal = (customerId: any, mobile_number: any) => {
    modalStore.closeLoginModal();
    closeRegisterModal();
    loggedInCustomerId.value = customerId;
    isOtpModalOpen.value = true;
    loggedInCustomerMobileNumber.value = mobile_number;
};

const closeOtpModal = () => {
    isOtpModalOpen.value = false;
};

const route: any = useRoute();
const router: any = useRouter();
function signout() {
    router.push({ path: '/' });
    store.logout();
}

onMounted(() => {
    window.document.addEventListener('click', handleClickOutside);
});

function closeOnLinkClick() {
    emit('update:isOpen', false);
    isShowProfile.value = false;
}
</script>

<template>
    <div v-if="isOpen"
        class="fwt-mobile-menu absolute top-[120px] left-1/2 transform -translate-x-1/2 sm:p-10 p-6 bg-gray-200 w-11/12 rounded-lg z-[55]">
        <ul class="fwt-mobile-menu-items flex flex-wrap">
            <li class="fwt-mobile-menu-list !pb-1 duration-500">
                <NuxtLink @click="closeOnLinkClick" :to="{ path: '/' }" :class="route.name == 'index' ? 'active' : ''"
                    class="text-base text-[#737373] font-semibold leading-tight uppercase tracking-wide">Home</NuxtLink>
                <!-- <a href="/" :class="route.name == 'index' ? 'active' : ''"
                    class="text-base text-[#737373] font-semibold leading-tight uppercase tracking-wide">Home</a> -->
            </li>
            <span class="px-1 text-xl text-[#737373] font-normal leading-tight uppercase tracking-wide"> | </span>
            <li class="fwt-mobile-menu-list !pb-0 duration-500">
                <NuxtLink @click="closeOnLinkClick" :to="{ path: '/about' }"
                    :class="route.name == 'about' ? 'active' : ''"
                    class="text-base text-[#737373] font-semibold leading-tight uppercase tracking-wide">About
                </NuxtLink>

                <!-- <a href="about" :class="route.name == 'about' ? 'active' : ''"
                    class="text-base text-[#737373] font-semibold leading-tight uppercase tracking-wide">about us</a> -->
            </li>
            <span class="px-1 text-xl text-[#737373] font-normal leading-tight uppercase tracking-wide"> | </span>
            <li class="fwt-mobile-menu-list !pb-0 duration-500">
                <NuxtLink @click="closeOnLinkClick" :to="{ path: '/book-tickets' }"
                    :class="route.name == 'book-tickets' ? 'active' : ''"
                    class="text-base text-[#737373] font-semibold leading-tight uppercase tracking-wide">book tickets
                </NuxtLink>
            </li>
            <span class="px-1 text-xl text-[#737373] font-normal leading-tight uppercase tracking-wide"> | </span>
            <li class="fwt-mobile-menu-list !pb-0 duration-500">
                <NuxtLink @click="closeOnLinkClick" :to="{ path: 'book-parcels' }"
                    :class="route.name == 'book-parcels' ? 'active' : ''"
                    class="text-base text-[#737373] font-semibold leading-tight uppercase tracking-wide">book parcel
                </NuxtLink>
            </li>
            <span class="px-1 text-xl text-[#737373] font-normal leading-tight uppercase tracking-wide"> | </span>
            <li class="fwt-mobile-menu-list !pb-0 duration-500">
                <NuxtLink @click="closeOnLinkClick" :to="{ path: 'book-charter' }"
                    :class="route.name == 'book-charter' ? 'active' : ''"
                    class="text-base text-[#737373] font-semibold leading-tight uppercase tracking-wide">book charter
                </NuxtLink>
            </li>
            <span class="px-1 text-xl text-[#737373] font-normal leading-tight uppercase tracking-wide"> | </span>
            <li class="fwt-mobile-menu-list !pb-0 duration-500">
                <NuxtLink @click="closeOnLinkClick" :to="{ path: 'buy-pass' }"
                    :class="route.name == 'buy-pass' ? 'active' : ''"
                    class="text-base text-[#737373] font-semibold leading-tight uppercase tracking-wide">buy pass
                </NuxtLink>
            </li>
            <span class="px-1 text-xl text-[#737373] font-normal leading-tight uppercase tracking-wide"> | </span>
            <li class="fwt-mobile-menu-list !pb-0 duration-500">
                <NuxtLink @click="closeOnLinkClick" :to="{ path: 'news' }" :class="route.name == 'news' ? 'active' : ''"
                    class="text-base text-[#737373] font-semibold leading-tight uppercase tracking-wide">news</NuxtLink>
            </li>
            <span class="px-1 text-xl text-[#737373] font-normal leading-tight uppercase tracking-wide"> | </span>
            <li class="fwt-mobile-menu-list pb-0 duration-500">
                <NuxtLink @click="closeOnLinkClick" :to="{ path: 'contact-us' }"
                    class="text-base text-[#737373] font-semibold leading-tight uppercase tracking-wide">contact us
                </NuxtLink>
            </li>
        </ul>
        <ul class="fwt-mobile-header-items flex justify-between flex-wrap mt-12">
            <li class="fwt-mobile-top-header-list mt-2">
                <a :href="`mailto:${myboatrideEmail}`" rel="noopener" target="_blank"
                    class="text-base font-semibold text-[#737373]">
                    <ClientOnly>
                        <font-awesome-icon icon="fa-solid fa-envelope" class="text-[#f69532]" />
                    </ClientOnly><span class="ml-2">{{ myboatrideEmail }}</span>
                </a>
            </li>
            <li class="fwt-mobile-top-header-list mt-2">
                <!-- <a :href="`https://wa.me/${myboatrideWhatsapp}`" target="_blank" class="text-base font-semibold text-[#737373]"> -->
                <a :href="`tel:${myboatrideWhatsapp}`" rel="noopener" target="_blank"
                    class="text-base font-semibold text-[#737373]">
                    <ClientOnly>
                        <font-awesome-icon icon="fa-solid fa-phone" class="text-[#f69532]" />
                    </ClientOnly><span class="ml-2">{{ myboatrideContact }}</span>
                </a>
            </li>
            <li class="fwt-mobile-top-header-list mt-2" ref="dropdown">

                <a v-if="!store.user.id" href="#" class="fwt-user-btn text-white text-base font-semibold"
                    @click="showLogin">
                    <ClientOnly>
                        <font-awesome-icon icon="fa-solid fa-user" class="text-[#f69532]" />
                        <span class="ml-2 capitalize">Login</span>
                        <font-awesome-icon icon="fa-solid fa-chevron-down" class="ml-2" />
                    </ClientOnly>
                </a>

                <div v-if="isLogin"
                    class="relative z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div class="fwt-user-name-dropdown">
                        <button @click="openLoginModal" class="text-gray-700 block pb-3 border-b">Customer</button>

                        <a :href="adminUrl" target="_blank" class="!text-gray-700 block pt-3">Boat Owner</a>
                    </div>
                </div>
                <a v-if="store.user.id" href="#" class="fwt-user-btn text-white text-base font-semibold" @click="showProfile">
                    <ClientOnly>
                        <font-awesome-icon icon="fa-solid fa-user" class="text-[#f69532]" />
                        <span class="ml-2 capitalize">{{ store.user.name }}</span>
                        <font-awesome-icon icon="fa-solid fa-chevron-down" class="ml-2" />
                    </ClientOnly>
                </a>
                <div v-if="isShowProfile"
                    class="relative z-10 mt-2 w-30 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div class="fwt-user-name-dropdown">
                        <NuxtLink @click="closeOnLinkClick" :to="{ path: 'profile' }" class="text-gray-700 block">My
                            Profile</NuxtLink>
                        <a href="#" @click="signout" class="text-gray-700 block pt-3">Logout</a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <LoginModal @show-otp-modal="openOtpModal" @show-register-modal="openRegisterModal" />
    <!-- :timer="timer" -->
    <OTPModal :isOTPModalOpen="isOtpModalOpen" @close="closeOtpModal" :customerId="loggedInCustomerId"
        :mobile_number="loggedInCustomerMobileNumber" />
    <RegisterModal :isRegisterModalOpen="isRegisterModalOpen" @close="closeRegisterModal" @show-otp-modal="openOtpModal"
        @show-login-modal="openLoginModal" />
</template>
