<script lang="ts" setup>
import { useAuthStore } from "~/store/auth";
import * as Yup from "yup";
import { ErrorMessage, Field, Form as VForm } from "vee-validate";
import SubmitButton from "./SubmitButton.vue";
import config from "~/config";
import { useModalStore } from "~/store/modal";
import { useReCaptcha } from 'vue-recaptcha-v3';

const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
const { $toast } = useNuxtApp();
const emit = defineEmits(["show-otp-modal", "show-register-modal"]);
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const modalStore = useModalStore();
const selectedCountryCode = ref("+91"); // Default to India (+91)
const login = Yup.object().shape({
  mobile_number: Yup.string()
    .required("Mobile number is required.")
    .matches(phoneRegExp, "Mobile number is not valid.")
    .min(10, "Mobile number must be of 10 digits.")
    .max(10, "Mobile number must be of 10 digits."),
});
const authStore = useAuthStore();
const countryList =config.countries;
const submitLoading = ref(false);
const onSubmitLogin = async (values: any) => {
  try {
    submitLoading.value = true;
    await recaptchaLoaded();
    const token = await executeRecaptcha('login');
    const fullMobileNumber = selectedCountryCode.value + values.mobile_number;

    const loginPayload: any = {
      ...values,
      mobile_number: fullMobileNumber,
      "g-recaptcha-response": token,
    };
    let loginApiResponse = await authStore.login(loginPayload);
    if (loginApiResponse.status == "success") {
      $toast.success(loginApiResponse.message);
      emit("show-otp-modal", loginApiResponse.data.id, fullMobileNumber);
    } else {
      $toast.error(loginApiResponse.message);
    }

    submitLoading.value = false;
  } catch (error) {
    $toast.error(config.catchErrorMessage);
    submitLoading.value = false;
  }
};

function showRegisterModal() {
  emit("show-register-modal");
}

const isNumber = (e: any) => {
  if (e.target.value.length > 9) {
    e.preventDefault();
  }
  return true;
};
</script>

<template>
  <div v-if="modalStore.isLoginModalOpen" id="modal" class="modal opacity-75"
    :class="modalStore.isLoginModalOpen ? 'show-modal' : ''" style="
      display: block;
      position: fixed;
      opacity: 1;
      z-index: 11000;
      left: 50%;
      margin-left: -925px;
      top: 100px;
    ">
    <div class="modal-content fwt-login-screen-popup">
      <span class="close-button modal_close" @click="modalStore.closeLoginModal">×</span>
      <div class="fwt-login-popup md:flex items-center">
        <div class="fwt-login-popup-logo md:mb-0 mb-5">
          <img src="assets/images/footer-logo.png" alt="footer-logo" />
        </div>
        <div class="fwt-login-popup-text md:mt-6 md:mb-6">
          <h2 class="fwt-text-heading">please sign in here.</h2>
          <VForm class="form w-100 md:mt-8 sm:mt-4 mt-3" id="kt_login_signin_form" @submit="onSubmitLogin"
            :validationSchema="login">
            <label for="mobile_number" class="required">Mobile Number</label>
            <div class="flex gap-x-2">

              <select v-model="selectedCountryCode"
                class="w-24 col-span-1 block  py-2 px-3 text-base text-gray-900 placeholder-gray-400 border border-gray-300 rounded-md focus:outline-none"
                tabindex="1">
                <option v-for="code in countryList" :key="code" :value="code">
                  {{ code }}
                </option>
              </select>

              <Field tabindex="1" type="number" name="mobile_number" autocomplete="off" @input="isNumber($event)" placeholder="Mobile number"
                class=" col-span-4 block w-full py-2 px-3 text-base text-gray-900 placeholder-gray-400 focus:outline-none" />

            </div>

            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="mobile_number" />
              </div>
            </div>
            <div class="fwt-login-btn-text sm:flex items-center sm:mt-5 mt-3">
              <SubmitButton :loading="submitLoading" />
              <p class="text-[#737373] ps-4 ">
                Don't have an account yet?<br />
                <span class="text-[#B8904F] cursor-pointer no-underline hover:underline" @click="showRegisterModal">Sign
                  Up</span>
              </p>
            </div>
          </VForm>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>