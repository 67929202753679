<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router";
import { ref, computed } from "vue";
import { useAuthStore } from "~/store/auth";
import { useModalStore } from "~/store/modal";
import LoginModal from "./LoginModal.vue";
import OTPModal from "./OTPModal.vue";

const isMobileMenuOpen = ref(false);
const isRegisterModalOpen = ref(false);
const isOtpModalOpen = ref(false);
const loggedInCustomerId = ref("");
const loggedInCustomerMobileNumber = ref(0);
const modalStore = useModalStore();
const store = useAuthStore();
const myboatrideContact = import.meta.env.VITE_APP_MYBOATRIDE_CONTACT;
const myboatrideEmail = import.meta.env.VITE_APP_MYBOATRIDE_EMAIL;
const myboatrideWhatsapp = import.meta.env.VITE_APP_MYBOATRIDE_WHATSAPP_NUMBER;
const adminUrl = import.meta.env.VITE_APP_ADMIN_URL

const openLoginModal = () => {
  closeRegisterModal();
  closeOtpModal();
  modalStore.openLoginModal();
};

const openMobileMenu = () => {
  isMobileMenuOpen.value = !isMobileMenuOpen.value;
};

const closeMobileMenu = () => {
  isMobileMenuOpen.value = false;
};

const openRegisterModal = () => {
  modalStore.closeLoginModal();
  isRegisterModalOpen.value = true;
};

const closeRegisterModal = () => {
  modalStore.closeLoginModal();
  isRegisterModalOpen.value = false;
};

const openOtpModal = (customerId: any, mobile_number: any) => {
  modalStore.closeLoginModal();
  closeRegisterModal();
  loggedInCustomerId.value = customerId;
  isOtpModalOpen.value = true;
  loggedInCustomerMobileNumber.value = mobile_number;
};

const closeOtpModal = () => {
  isOtpModalOpen.value = false;
};

const route: any = useRoute();
const router = useRouter();

function signout() {
  // console.log('Signing out...');
  router.push({ path: "/" });
  store.logout();
}
</script>


<template>

  <header class="absolute left-0 right-0">

    <div class="container mx-auto max-w-[1372px]">
      <div class="nav flex items-center justify-between pt-10 pb-6">
        <div class="fwt-header-logo">
          <NuxtLink :to="{ path: '/' }">
            <img src="assets/images/Logo.svg" alt="" />
          </NuxtLink>
        </div>
        <ul class="xl:flex hidden">
          <li class="ml-11">
            <!-- <a href="https://maps.app.goo.gl/Hv8oFhkV711rcFam6" target="_blank" class="text-white text-base font-semibold">
                            <ClientOnly>
                                <font-awesome-icon icon="fa-solid fa-location-dot" class="text-[#f69532]" />
                            </ClientOnly>
                            <span class="ml-2">5th Floor, 505, Hubtown Solaris, Andheri, Mumbai</span>
                        </a> -->
            <a
              :href="`mailto:${myboatrideEmail}`"
              target="_blank"
              class="text-white text-base font-semibold"
              rel="noopener"
            >
              <ClientOnly>
                <font-awesome-icon
                  icon="fa-solid fa-envelope"
                  class="text-[#f69532]"
                />
              </ClientOnly>
              <span class="ml-2">{{ myboatrideEmail }}</span>
            </a>
          </li>
          <li class="ml-11">
            <a
              :href="`tel:${myboatrideWhatsapp}`"
              target="_blank"
              class="text-white text-base font-semibold"
              rel="noopener"
            >
              <!-- <a :href="`https://wa.me/${myboatrideWhatsapp}`" target="_blank" class="text-white text-base font-semibold" rel="noopener"> -->
              <ClientOnly>
                <font-awesome-icon
                  icon="fa-solid fa-phone"
                  class="text-[#f69532]"
                />
              </ClientOnly>
              <span class="ml-2">{{ myboatrideContact }}</span>
            </a>
          </li>

          <li class="relative fwt-top-header-list ml-11" v-if="!store.user.id">
            <a href="#" class="fwt-user-btn text-white text-base font-semibold">
              <ClientOnly>
                <font-awesome-icon
                  icon="fa-solid fa-user"
                  class="text-[#f69532]"
                />
                <span class="ml-2 capitalize">Login</span>
                <font-awesome-icon
                  icon="fa-solid fa-chevron-down"
                  class="ml-2  "
                />
              </ClientOnly>
            </a>
            <div
              class="absolute profile-card invisible left-0 z-10  w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="fwt-user-name-dropdown">
                <!-- <NuxtLink :to="" class="text-gray-700 block">Admin</NuxtLink> -->
                <button @click="openLoginModal" class="text-gray-700 block pb-3 border-b">
                  Customer
                </button>
                <!-- <hr class="pb-3 "> -->
                <a :href="adminUrl" target="_blank" class="text-gray-700 block cursor-pointer pt-3">Boat Owner</a>
              </div>
            </div>
          </li>


          <li class="relative fwt-top-header-list ml-11" v-if="store.user.id">
            <a href="#" class="fwt-user-btn text-white text-base font-semibold">
              <ClientOnly>
                <font-awesome-icon
                  icon="fa-solid fa-user"
                  class="text-[#f69532]"
                /><span class="ml-2 capitalize">{{ store.user.name }}</span>
                <font-awesome-icon
                  icon="fa-solid fa-chevron-down"
                  class="ml-7"
                />
              </ClientOnly>
            </a>
            <div
              class="absolute profile-card invisible left-0 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="fwt-user-name-dropdown">
                <NuxtLink v-if="store.user.id" to="/profile" class="text-gray-700 block"
                  >My Profile</NuxtLink
                >
                <button @click="signout" class="text-gray-700 block pt-3">
                  Logout
                </button>
              </div>
            </div>
          </li>
        </ul>

        <div
          class="fwt-toggle-menu relative xl:hidden block"
          @click="openMobileMenu"
          :class="isMobileMenuOpen ? 'cross' : ''"
        >
          <span
            class="w-10 h-[3px] bg-white mb-2 block rounded-sm duration-700"
          ></span>
          <span
            class="w-10 h-[3px] bg-white mb-2 block rounded-sm duration-700"
          ></span>
          <span
            class="w-10 h-[3px] bg-white mb-2 block rounded-sm duration-700"
          ></span>
        </div>
      </div>

      <div class="xl:flex hidden justify-end">
        <ul class="max-w-[902px] w-full flex justify-between">
          <li
            class="pe-0 relative py-5 after:content-[''] after:absolute after:left-0 after:-top-1 after:w-full after:h-px after:bg-[#737373] cursor-pointer after:hover:bg-[#f69532] after:duration-1000 group"
          >
            <NuxtLink
              :class="route.name == 'index' ? 'active' : ''"
              class="text-white text-base font-semibold uppercase group-hover:text-[#f69532] duration-1000"
              to="/"
              >Home</NuxtLink
            >
          </li>
          <li
            class="pe-0 relative py-5 after:content-[''] after:absolute after:left-0 after:-top-1 after:w-full after:h-px after:bg-[#737373] cursor-pointer after:hover:bg-[#f69532] after:duration-1000 group"
          >
            <NuxtLink
              :class="route.name == 'about' ? 'active' : ''"
              to="/about"
              class="text-white text-base font-semibold uppercase group-hover:text-[#f69532] duration-1000"
            >
              about us</NuxtLink
            >
          </li>
          <li
            class="pe-0 relative py-5 after:content-[''] after:absolute after:left-0 after:-top-1 after:w-full after:h-px after:bg-[#737373] cursor-pointer after:hover:bg-[#f69532] after:duration-1000 group"
          >
            <NuxtLink
              :class="
                route.name == 'book-tickets' ||
                route.name == 'passenger-info' ||
                route.name == 'booking-details'
                  ? 'active'
                  : ''
              "
              to="/book-tickets"
              class="text-white text-base font-semibold uppercase group-hover:text-[#f69532] duration-1000"
            >
              book tickets</NuxtLink
            >
          </li>
          <li
            class="pe-0 relative py-5 after:content-[''] after:absolute after:left-0 after:-top-1 after:w-full after:h-px after:bg-[#737373] cursor-pointer after:hover:bg-[#f69532] after:duration-1000 group"
          >
            <NuxtLink
              :class="route.name.includes('parcel') ? 'active' : ''"
              to="/book-parcels"
              class="text-white text-base font-semibold uppercase group-hover:text-[#f69532] duration-1000"
            >
              book parcel</NuxtLink
            >
          </li>
          <li
            class="pe-0 relative py-5 after:content-[''] after:absolute after:left-0 after:-top-1 after:w-full after:h-px after:bg-[#737373] cursor-pointer after:hover:bg-[#f69532] after:duration-1000 group"
          >
            <NuxtLink
              :class="route.name.includes('charter') ? 'active' : ''"
              to="/book-charter"
              class="text-white text-base font-semibold uppercase group-hover:text-[#f69532] duration-1000"
            >
              book charter</NuxtLink
            >
          </li>
          <li
            class="pe-0 relative py-5 after:content-[''] after:absolute after:left-0 after:-top-1 after:w-full after:h-px after:bg-[#737373] cursor-pointer after:hover:bg-[#f69532] after:duration-1000 group"
          >
            <NuxtLink
              :class="route.name == 'buy-pass' ? 'active' : ''"
              to="/buy-pass"
              class="text-white text-base font-semibold uppercase group-hover:text-[#f69532] duration-1000"
            >
              buy pass</NuxtLink
            >
          </li>
          <li
            class="pe-0 relative py-5 after:content-[''] after:absolute after:left-0 after:-top-1 after:w-full after:h-px after:bg-[#737373] cursor-pointer after:hover:bg-[#f69532] after:duration-1000 group"
          >
            <NuxtLink
              :class="route.name == 'news' ? 'active' : ''"
              to="/news"
              class="text-white text-base font-semibold uppercase group-hover:text-[#f69532] duration-1000"
            >
              news</NuxtLink
            >
          </li>
          <!-- <li
                        class="fwt-menu-list pe-8 relative py-5 after:content-[''] after:absolute  after:left-0 after:-top-1 after:w-full after:h-px after:bg-[#737373] cursor-pointer after:hover:bg-[#f69532] after:duration-1000 group">
                        <NuxtLink to="#" :class="(route.name == 'policy' || route.name == 'terms-conditions' || route.name == 'book-tickets' || route.name == 'book-parcelss') ? 'active' : ''"
                            class="text-white text-base font-semibold uppercase group-hover:text-[#f69532] duration-1000">
                            more
                            <ClientOnly>
                                <font-awesome-icon icon="fa-solid fa-chevron-down"
                                    class="absolute top-1/2 -translate-y-2/4 right-0" />
                            </ClientOnly>
                        </NuxtLink>
                        <ul class="fwt-mega-menu absolute top-12 left-0 py-5 w-52">
                            <li class="fwt-mega-menu_list pb-3">
                                <NuxtLink to="policy" :class="route.name == 'policy' ? 'active' : ''" class="text-[#737373] text-base font-semibold uppercase">Privacy
                                    Policy</NuxtLink>
                            </li>
                            <li class="fwt-mega-menu_list pb-3">
                                <NuxtLink to="terms-conditions" :class="route.name == 'terms-conditions' ? 'active' : ''" class="text-[#737373] text-base font-semibold uppercase">
                                    Terms
                                    &
                                    Conditions</NuxtLink>
                            </li>
                            <li class="fwt-mega-menu_list pb-3"><NuxtLink to="book-tickets" :class="route.name == 'book-tickets' ? 'active' : ''" class="text-[#737373] text-base font-semibold uppercase">
                                    Book tickets</NuxtLink>
                            </li>
                            <li class="fwt-mega-menu_list pb-3"><NuxtLink to="book-parcelss"
                                    class="text-[#737373] text-base font-semibold uppercase" :class="route.name == 'book-parcelss' ? 'active' : ''">book parcels</NuxtLink>
                            </li>
                        </ul>
                    </li> -->
                    <li
                        class="pe-14 relative py-5 after:content-[''] after:absolute  after:left-0 after:-top-1 after:w-full after:h-px after:bg-[#737373] cursor-pointer after:hover:bg-[#f69532] after:duration-1000 group">
                        <NuxtLink to="/contact-us" :class="route.name == 'contact-us' ? 'active' : ''"
                            class="text-white text-base font-semibold uppercase group-hover:text-[#f69532] duration-1000">
                            contact
                            us</NuxtLink>
                    </li>
                </ul>
            </div>
        </div>
    </header>
    <MobileMenu v-model:isOpen="isMobileMenuOpen" @close="closeMobileMenu" />
    <LoginModal @show-otp-modal="openOtpModal"
        @show-register-modal="openRegisterModal" />
    <!-- :timer="timer" -->
    <OTPModal :isOTPModalOpen="isOtpModalOpen" @close="closeOtpModal" :customerId="loggedInCustomerId"
        :mobile_number="loggedInCustomerMobileNumber" />
    <RegisterModal :isRegisterModalOpen="isRegisterModalOpen" @close="closeRegisterModal" @show-otp-modal="openOtpModal"
        @show-login-modal="openLoginModal" />
</template>