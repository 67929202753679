<script setup lang="ts">
import SubmitButton from './SubmitButton.vue';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form as VForm } from 'vee-validate';
import { useAuthStore } from '~/store/auth';
import config from '~/config';

const props = defineProps({
    isRegisterModalOpen: {
        type: Boolean,
        default: false
    },
    bookingType: {
        type: String,
        default: 'ticket'
    }
});
interface ValidationContext {
    aadhar_no: string;
}
const emit = defineEmits(['close', 'show-otp-modal', 'show-login-modal']);

const close = () => {
    emit('close');
};
function showLoginModal() {
    emit('show-login-modal');
}
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const aadhaarRegExp = /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/;
const register = Yup.object().shape({
    name: Yup.string()
        .required("Name is required."),
    mobile_number: Yup.string()
        .required("Mobile number is required.")
        .matches(phoneRegExp, 'Mobile number is not valid.')
        .min(10, "Mobile number must be of 10 digits.")
        .max(10, "Mobile number must be of 10 digits."),
    email: Yup.string()
        .required("Email is required."),

    aadhar_no: Yup.string().when('bookingType', {
        is: (type: string) => type === 'ticket' || type === 'charter',
        then: () => Yup.string()
            .required('Aadhar no is required')
            .length(12, 'Aadhar no must contain exactly 12 digits only')
            .matches(aadhaarRegExp, 'Aadhar no must contain 12 digits only and start with any digit from 2 to 9'),
        otherwise:() => Yup.string().nullable()
    })

});
const selectedCountryCode = ref("+91");
const countryList = config.countries;
const authStore = useAuthStore();
const submitLoading = ref(false);
const { $toast } = useNuxtApp();
const onSubmitRegister = async (values: any) => {
    try {
        const fullMobileNumber = selectedCountryCode.value + values.mobile_number;

        if (values.aadhar_no) {
            values.aadhar_no = values.aadhar_no.replace(/\s+/g, '');
        }
        values.mobile_number = fullMobileNumber;

        submitLoading.value = true;
        // Send otp verification request
        const response = await authStore.register(values);
        if (response.status == 'success') {
            $toast.success(response.message);
            emit('show-otp-modal', response.data);
        } else {
            $toast.error(response.message);
        }
        submitLoading.value = false;
    } catch (error) {
        $toast.error(config.catchErrorMessage);
        submitLoading.value = false;
    }
};
const rawInput = ref('');
const formattedInput = computed({
    get() {
        return rawInput.value;
    },
    set(value: any) {
        value = rawInput.value;
    }
});
function formatInput(event: any) {
    if (event) {
        let value = event.target.value.replace(/\D/g, '');
        const chunks = value.match(/.{1,4}/g);
        rawInput.value = chunks ? chunks.join(' ') : '';
    }
}
</script>

<template>
    <div v-if="isRegisterModalOpen" id="modal" class="modal opacity-75" :class="isRegisterModalOpen ? 'show-modal' : ''"
        style="display: block; position: fixed; opacity: 1; z-index: 11000; left: 50%; margin-left: -925px; top: 100px;">
        <div class="modal-content fwt-login-screen-popup">
            <span class="close-button modal_close" @click="close">×</span>
            <div class="user_login">
                <div class="fwt-register-popup md:flex items-center">
                    <div class="fwt-login-popup-logo md:mb-0 mb-5 md:w-2/6 w-full">
                        <img src="assets/images/footer-logo.png" alt="footer-logo">
                    </div>
                    <div class="fwt-login-popup-text md:w-3/5 w-full">
                        <!-- <p class="uppercase mb-2.5">new User !</p> -->
                        <h2 class="fwt-text-heading sm:pb-3 pb-2">please sign up here.</h2>
                        <VForm class="form w-100" id="kt_verify_otp_form" @submit="onSubmitRegister"
                            :validationSchema="register">
                            <label for="" class="required text-base mt-4 capitalize text-[#737373]">name</label>
                            <Field tabindex="1" type="text" class="" name="name" autocomplete="off"
                                @keypress.enter.prevent />
                            <div class="fv-plugins-message-container">
                                <div class="fv-help-block">
                                    <ErrorMessage name="name" />
                                </div>
                            </div>
                            <label for="" class="required text-base mt-4 capitalize text-[#737373]">mobile
                                number </label>
                            <div class="flex gap-x-2">

                                <select v-model="selectedCountryCode"
                                    class="w-24 col-span-1 block  py-2 px-3 text-base text-gray-900 placeholder-gray-400 border border-gray-300 rounded-md focus:outline-none"
                                    tabindex="1">
                                    <option v-for="code in countryList" :key="code" :value="code">
                                        {{ code }}
                                    </option>
                                </select>

                                <Field type="text" name="mobile_number"
                                    class=" col-span-4 block w-full py-2 px-3 text-base text-gray-900 placeholder-gray-400 focus:outline-none"
                                    placeholder="Mobile number" tabindex="2" autocomplete="off" />

                            </div>
                            <div class="fv-plugins-message-container">
                                <div class="fv-help-block">
                                    <ErrorMessage name="mobile_number" />
                                </div>
                            </div>
                            <label for="" class="required text-base mt-4 capitalize text-[#737373]">email</label>
                            <Field tabindex="3" type="text" class="" name="email" autocomplete="off"
                                @keypress.enter.prevent />
                            <div class="fv-plugins-message-container">
                                <div class="fv-help-block">
                                    <ErrorMessage name="email" />
                                </div>
                            </div>
                            <label for="" class="required text-base mt-4 capitalize text-[#737373]">Aadhar No</label>
                            <Field tabindex="4" type="text" class="" name="aadhar_no" v-model="formattedInput"
                                @input="formatInput" maxlength="14" minlength="14" autocomplete="off"
                                @keypress.enter.prevent />
                            <div class="fv-plugins-message-container">
                                <div class="fv-help-block">
                                    <ErrorMessage name="aadhar_no" />
                                </div>
                            </div>
                            <div class="fwt-login-btn-text sm:flex items-center sm:mt-5 mt-3">
                                <SubmitButton :loading="submitLoading" />
                                <p class="text-[#737373] ps-4 capitalize">already have an account? <br>
                                    <span class="text-[#B8904F] cursor-pointer no-underline hover:underline"
                                        @click="showLoginModal">back to sign in</span>
                                </p>
                            </div>
                        </VForm>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.form-select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.size-5 {
    width: 1.25rem;
    height: 1.25rem;
}
</style>