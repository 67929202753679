<script setup lang="ts">
const myboatrideContact = import.meta.env.VITE_APP_MYBOATRIDE_CONTACT;
const myboatrideWhatsapp = import.meta.env.VITE_APP_MYBOATRIDE_WHATSAPP_NUMBER;
const myboatrideEmail = import.meta.env.VITE_APP_MYBOATRIDE_EMAIL;
const myboatrideFacebook = import.meta.env.VITE_APP_FACEBOOK_URL;
const myboatrideInsta = import.meta.env.VITE_APP_INSTAGRAM_URL;
const myboatrideTwitter = import.meta.env.VITE_APP_TWITTER_URL;
// const myboatrideYoutube = import.meta.env.VITE_APP_YOUTUBE_URL;
// const myboatrideLinkedIn = import.meta.env.VITE_APP_LINKEDIN_URL;
</script>
<template>
    <footer>
        <div class="container max-w-[1643px] pr-[60px] mx-auto">
            <div class="fwt-footer-content columns-2 flex items-center gap-32">
                <div class="fwt-map-content">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.8396663005124!2d72.84742457574961!3d19.11468848209792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9cd1657c6e7%3A0x2acf8f11dd0989cb!2sHubtown%20Solaris!5e0!3m2!1sen!2sin!4v1724940607336!5m2!1sen!2sin"
                        class="relative max-w-[943px] w-full h-full min-h-[700px]" title="My Boat Ride Address"></iframe>
                </div>
                <div class="fwt-footer-text">
                    <div class="fwt-footer-logo mb-10">
                        <img src="assets/images/footer-logo.png" alt="" />
                    </div>
                    <div class="fwt-footer-cont-text flex md:mt-20 mt-4">
                        <ul class="fwt-footer-items-left">
                            <h4 class="font-bold text-[#737373] mb-2 uppercase">
                                <span>
                                    <client-only>
                                        <font-awesome-icon icon="fa-solid fa-link" class="text-[#f69532] mr-3" />
                                    </client-only>
                                </span>
                                quick links
                            </h4>
                            <li class="fwt-footer-list">
                                <NuxtLink to="/about" class="text-base leading-7 font-semibold text-[#737373] ml-8">About Us</NuxtLink>
                            </li>
                            <li class="fwt-footer-list">
                                <NuxtLink to="/book-tickets" class="text-base leading-7 font-semibold text-[#737373] ml-8">Book Tickets</NuxtLink>
                            </li>
                            <li class="fwt-footer-list">
                                <NuxtLink to="/book-parcels" class="text-base leading-7 font-semibold text-[#737373] ml-8">Book Parcel</NuxtLink>
                            </li>
                            <li class="fwt-footer-list">
                                <NuxtLink to="/book-charter" class="text-base leading-7 font-semibold text-[#737373] ml-8">Book Charter</NuxtLink>
                            </li>
                            <li class="fwt-footer-list">
                                <NuxtLink to="/buy-pass" class="text-base leading-7 font-semibold text-[#737373] ml-8">Buy Pass</NuxtLink>
                            </li>
                            <li class="fwt-footer-list">
                                <NuxtLink to="/news" class="text-base leading-7 font-semibold text-[#737373] ml-8">News</NuxtLink>
                            </li>
                            <li class="fwt-footer-list">
                                <NuxtLink to="/policy" class="text-base leading-7 font-semibold text-[#737373] ml-8">Privacy Policy</NuxtLink>
                            </li>
                            <li class="fwt-footer-list">
                                <NuxtLink to="/terms-conditions" class="text-base leading-7 font-semibold text-[#737373] ml-8">Terms &
                                    Conditions</NuxtLink>
                            </li>
                        </ul>

                        <div class="fwt-footer-items-right ml-[53px]">
                            <ul class="fwt-footer-contact">
                                <li>
                                    <a :href="`tel:${myboatrideWhatsapp}`" target="_blank" class="flex text-base items-center font-semibold text-[#737373]" rel="noopener">
                                        <ClientOnly>
                                            <font-awesome-icon icon="fa-solid fa-phone" class="text-[#f69532] mr-3" />
                                        </ClientOnly>
                                        {{ myboatrideContact }}
                                    </a>
                                </li>
                                <li class="mt-6">
                                    <a :href="`mailto:${myboatrideEmail}`" target="_blank"
                                        class="flex text-base items-center font-semibold text-[#737373]" rel="noopener">
                                        <ClientOnly>
                                            <font-awesome-icon icon="fa-solid fa-envelope" class="text-[#f69532] mr-3" />
                                        </ClientOnly>
                                        {{ myboatrideEmail }}
                                    </a>
                                </li>
                            </ul>
                            <ul class="fwt-footer-icon flex items-center mt-6">
                                <li class="fwt-social-icon">
                                    <a target="_blank" :href="`https://wa.me/${myboatrideWhatsapp}`" rel="noopener"
                                        class="w-11 h-11 flex items-center justify-center bg-[#A3B9D2] rounded-full duration-500 hover:bg-[#114C8E]">
                                        <client-only>
                                            <font-awesome-icon icon="fa-brands fa-whatsapp" class="text-white" />
                                        </client-only>
                                    </a>
                                </li>
                                <li class="fwt-social-icon">
                                    <a target="_blank" :href="myboatrideFacebook"
                                        class="w-11 h-11 flex items-center justify-center bg-[#A3B9D2] rounded-full ml-3 duration-500 hover:bg-[#114C8E]">
                                        <client-only>
                                            <font-awesome-icon icon="fa-brands fa-facebook-f" class="text-white" />
                                        </client-only>
                                    </a>
                                </li>
                                <li class="fwt-social-icon">
                                    <a target="_blank" :href="myboatrideInsta"
                                        class="w-11 h-11 flex items-center justify-center bg-[#A3B9D2] rounded-full ml-3 duration-500 hover:bg-[#114C8E]">
                                        <client-only>
                                            <font-awesome-icon icon="fa-brands fa-instagram" class="text-white" />
                                        </client-only>
                                    </a>
                                </li>
                                <li class="fwt-social-icon">
                                    <a target="_blank" :href="myboatrideTwitter"
                                        class="w-11 h-11 flex items-center justify-center bg-[#A3B9D2] rounded-full ml-3 duration-500 hover:bg-[#114C8E]">
                                        <client-only>
                                            <font-awesome-icon icon="fa-brands fa-twitter" class="text-white" />
                                        </client-only>
                                    </a>
                                </li>
                                <!-- <li class="fwt-social-icon">
                                    <a target="_blank" :href="myboatrideLinkedIn"
                                        class="w-11 h-11 flex items-center justify-center bg-[#A3B9D2] rounded-full ml-3 duration-500 hover:bg-[#114C8E]">
                                        <client-only>
                                            <font-awesome-icon icon="fa-brands fa-linkedin-in" class="text-white" />
                                        </client-only>
                                    </a>
                                </li> -->
                                <!-- <li class="fwt-social-icon">
                                    <a target="_blank" :href="myboatrideYoutube"
                                        class="w-11 h-11 flex items-center justify-center bg-[#A3B9D2] rounded-full ml-3 duration-500 hover:bg-[#114C8E]">
                                        <client-only>
                                            <font-awesome-icon icon="fa-brands fa-youtube" class="text-white" />
                                        </client-only>
                                    </a>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                    <p class="fwt-simple-text mt-8 ml-8 text-gray-500">
                        All Rights Reserved @ {{ new Date().getFullYear() }}
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>
