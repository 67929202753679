<template>
  <div>
    <Header />
    <Loader :isLoading="loading" />
    <slot />
    <Footer />
  </div>
</template>

<script lang="ts" setup>
const loading = ref(true);

onMounted(() => {
  loading.value = false;
});
</script>